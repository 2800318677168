(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-timelimits/assets/javascripts/get-player-timelimits.js') >= 0) return;  svs.modules.push('/components/accountservices/player-timelimits/assets/javascripts/get-player-timelimits.js');
"use strict";

let logger;
let jupiter;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  logger = trinidad.logger('accountservices:player-timelimits');
  jupiter = trinidad.jupiter;
} else {
  logger = svs.core.log.getLogger('accountservices:player-timelimits');
  jupiter = svs.core.jupiter;
}

const fetchPlayerGameLimits = async req => {
  let data;
  try {
    const options = {
      method: 'get',
      path: '/player/1/gamelimits'
    };
    if (svs.isServer) {
      options.req = req;
    }
    data = await jupiter.callAsync(options);
    return [null, data];
  } catch (err) {
    logger.info('fetch-player-timelimits error', err);
    return [err, null];
  }
};
const getPlayerTimeLimits = async req => {
  if (svs.isServer) {
    if (!req.userSession.hasRole(req.userSession.roles.PLAYER)) {
      throw new Error('Player not logged in');
    }
  } else if (!svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
    throw new Error('Player not logged in');
  }
  const [err, data] = await fetchPlayerGameLimits(req);
  if (data !== null && data !== void 0 && data.response) {
    var _data$response;
    return (_data$response = data.response) === null || _data$response === void 0 ? void 0 : _data$response.time;
  }
  logger.info('error while trying to fetch player game limits:', err);
  return null;
};
if (svs.isServer) {
  module.exports = {
    getPlayerTimeLimits
  };
} else {
  setGlobal('svs.accountServices.player_timelimits', {
    getPlayerTimeLimits
  });
}

 })(window);